import { Image } from "./image";

export const GalleryWeb = (props) => {
  return (
    <>
      <div id="portfolio-header" className="text-center">
        <div className="section-title">
          <h2>Our Portfolio</h2>
          <p>
            We have delivered numerous projects for our customers, here is some
            of our work:
          </p>
        </div>
      </div>

      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Websites:</h2>
            <p>
              Below are some of the websites we delivered to our customers, it
              ranges from online stores to medical services and businesses:
            </p>
          </div>
          <div className="row">
            <div className="portfolio-items">
              {props.data
                ? props.data.map((d, i) => (
                    <div
                      key={`${d.title}-${i}`}
                      className="col-sm-6 col-md-4 col-lg-4"
                    >
                      <Image
                        title={d.title}
                        link={d.link}
                        smallImage={d.smallImage}
                      />
                    </div>
                  ))
                : "Loading..."}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
