import { Image } from "./image";

export const GalleryMobile = (props) => {
  return (
    <>
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Mobile Applications:</h2>
            <p>
              Here is some of the mobile applications that we developed and delivered to our customers:
            </p>
          </div>
          <div className="row">
            <div className="portfolio-items">
              {props.data
                ? props.data.map((d, i) => (
                    <div
                      key={`${d.title}-${i}`}
                      className="col-sm-6 col-md-4 col-lg-4"
                    >
                      <Image
                        title={d.title}
                        link={d.link}
                        smallImage={d.smallImage}
                      />
                    </div>
                  ))
                : "Loading..."}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
